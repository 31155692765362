<template>
  <div>
    <h1 class="display-5 mb-4">
      How to connect Surfe to Copper
    </h1>

    <p class="text-muted mb-2">
      June 12, 2021
    </p>

    <p>
      Welcome to this onboarding guide.<br>
      On this page, you'll find information to help you connect Surfe to your Copper CRM.
    </p>

    <h1 class="mt-3">
      Check you current Copper plan
    </h1>

    <p>Surfe requires API access to your Copper instance.</p>

    <p>
      As of now, API access is only enabled for <b>Professional</b> and <b>Business</b> plans. Check out <a href="https://www.copper.com/pricing">Copper's pricing page</a> for more information.</p>

    <h1 class="mt-3">
      Generate your personal API key
    </h1>

    <p>
      Every Surfe user must generate their own personal API Key to use Surfe.
    </p>

    <p>
      Go to <b>Settings > API Keys</b> and click on <b>Generate API Key</b>.
    </p>

    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="InfoIcon"
        />
        <span class="ml-1">You may have to ask your Copper admin to generate this key for you.</span>
      </div>
    </b-alert>

    <p>A new API Key should appear in the list, with your name and email.</p>

    <b-img
      fluid
      :src="require('@/assets/images/support/copper_api_key.png')"
      style="width:100%"
      class="my-2"
    />

    <h1 class="mt-3">
      Paste your API key in Surfe
    </h1>

    <p>
      Once your personal API key is generated, <b>copy-paste it</b> in the Surfe welcome panel, alongside your email address.
    </p>

    <b-img
      fluid
      :src="require('@/assets/images/support/copper_paste.png')"
      style="width:100%"
      class="my-2"
    />

    <p>
      Click on <b>Continue</b>.<br>
      You're all set!
    </p>
  </div>
</template>

<script>
import { BAlert, BImg } from 'bootstrap-vue'

export default {
  components: {
    BAlert, BImg,
  },
}
</script>

<style scoped>
p {
  padding: 10px 0;
  font-size: 16px;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}
</style>
