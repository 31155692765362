import { render, staticRenderFns } from "./OnboardingCopper.vue?vue&type=template&id=64c78aa3&scoped=true&"
import script from "./OnboardingCopper.vue?vue&type=script&lang=js&"
export * from "./OnboardingCopper.vue?vue&type=script&lang=js&"
import style0 from "./OnboardingCopper.vue?vue&type=style&index=0&id=64c78aa3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c78aa3",
  null
  
)

export default component.exports